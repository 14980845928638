import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../views/Properties.vue'),
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/Users.vue'),
  },
  {
    path: '/properties',
    name: 'properties',
    component: () => import('../views/Properties.vue'),
  },
  {
    path: '/deletedProperties',
    name: 'deletedProperties',
    component: () => import('../views/deletedProperties.vue'),
  },
  {
    path: '/rentalFiles',
    name: 'rentalFiles',
    component: () => import('../views/RentalFiles.vue'),
  },
  {
    path: '/applications',
    name: 'applications',
    component: () => import('../views/Applications.vue'),
  },
  {
    path: '/paymentApps',
    name: 'paymentApps',
    component: () => import('../views/PaymentApps.vue'),
  },
  {
    path: '/paymentAppsFlows',
    name: 'paymentAppsFlows',
    component: () => import('../views/paymentAppsFlows.vue'),
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('../views/Transactions.vue'),
  },
  {
    path: '/bookings',
    name: 'bookings',
    component: () => import('../views/Bookings.vue'),
  },
  {
    path: '/edit/booking/:bookingUid',
    name: 'editBooking',
    component: () => import('../views/_id/EditBooking.vue'),
  },
  {
    path: '/booking/:bookingUid',
    name: 'booking',
    component: () => import('../views/_id/Booking.vue'),
  },
  {
    path: `/user/:userUid`,
    name: 'user',
    component: () => import('../views/_id/User.vue'),
  },
  {
    path: `/property/:propertyUid`,
    name: 'property',
    component: () => import('../views/_id/Property.vue'),
  },
  {
    path: `/rentalFile/:rentalFileUid`,
    name: 'rentalFile',
    component: () => import('../views/_id/RentalFile.vue'),
  },
  {
    path: `/application/:applicationUid`,
    name: 'application',
    component: () => import('../views/_id/Application.vue'),
  },
  {
    path: `/tenant/:tenantUid`,
    name: 'tenant',
    component: () => import('../views/_id/Tenant.vue'),
  },
  {
  path: `/paymentApp/:paymentAppUid`,
    name: 'paymentApp',
    component: () => import('../views/_id/PaymentApp.vue'),
  },
  
  // {
  //   path: '/content',
  //   name: 'content',
  //   component: () => import('../views/Content.vue'),
  // },
  // {
  //   path: `/usersTypes`,
  //   name: 'usersTypes',
  //   component: () => import('../views/content/UsersTypes.vue'),
  // },
  // {
  //   path: `/usersContacsReasons`,
  //   name: 'usersContacsReasons',
  //   component: () => import('../views/content/UsersContactsReasons.vue'),
  // },
  // {
  //   path: `/propertiesTypes`,
  //   name: 'propertiesTypes',
  //   component: () => import('../views/content/PropertiesTypes.vue'),
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// permet la redirection au submit du login et de bloquer le passage par l'url pour accéder à pandadmin
router.beforeEach((to, from, next) => {
  if(to.name !== 'login' && !localStorage.getItem('token')){
    localStorage.setItem('redirect', location.pathname + location.search)
    next({ path: '/login' })
  }else {
    next()
  }
})

export default router
