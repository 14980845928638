import Vue from 'vue'
import App from './App.vue'
import VueGoogleMap from 'vuejs-google-maps'
import vuetify from './plugins/vuetify';
import router from './router'

Vue.config.productionTip = false

Vue.use(VueGoogleMap, {
  load: {
    apiKey: "AIzaSyCjZC5YGKbHDD3gXUTUac7IqyucdciWvLk",
    libraries: ["places"],
    region: 'FR',
    language: 'FR',
  }
});


Vue.filter('lowercase', str => {
	
	if (!str) {
		return "";
	}
	return str.toLowerCase();
})

Vue.filter('uppercase', str => {
	
	if (!str) {
		return "";
	}
	return str.toUpperCase();
})

Vue.filter('capitalise', str => {

	if (!str) {
		return "";
	}
	return str.charAt(0).toUpperCase() + str.slice(1);
})
Vue.filter('date', str => {
	
	if (!str) {
		return "";
	}

	const date = new Date(str)
	return date.toLocaleString(['fr-FR'], {
		timeZone: 'Europe/Paris',
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	})
})

Vue.filter('date-n-hours', str => {
	
	if (!str) {
		return "";
	}
	
	const date = new Date(str)
	return date.toLocaleString(['fr-FR'], {
		timeZone: 'Europe/Paris',
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	}) + " à " + date.getHours() + "h" + date.getUTCMinutes()
})

Vue.filter('dateShort', (str) => {
	
	if (!str) {
		return "";
	}
	
	const date = new Date(str)
	return date.toLocaleString(['fr-FR'], {
		timeZone: 'Europe/Paris',
		month: 'long',
		year: 'numeric',
	})
})


Vue.filter('age', value => {
	
	if (!value) {
		return "";
	}
	
    const ageDifMs = Date.now() - new Date(value).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
})

Vue.filter('price', str => {
	if (!str) {
		return "0";
	}
	
	return new Intl.NumberFormat('fr-FR').format(str)
})

Vue.filter('firstword', str => {
	
	if (!str) {
		return "";
	}

	return str.split(' ')[0]
})





new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
